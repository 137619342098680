"use strict";

$(document).ready(function () {
  $(function () {
    // On wp
    var wpAdmin = $(document).find('#wpadminbar');

    if (wpAdmin.length !== 0) {
      header.classList.add('logged-admin');
    }

    var header = $('.header'),
        html = $('html'),
        burgerBtn = header.find('.js-toggler'),
        closeBtn = header.find('.js-close-btn'),
        menuMob = header.find('.js-navbar-mobile'),
        overlay = header.find('.navbar-overlay');
    burgerBtn.on('click', function () {
      if (burgerBtn.hasClass('opened')) {
        closeMenu();
      } else {
        burgerBtn.addClass('opened');
        menuMob.addClass('opened');
        overlay.fadeIn(700);
        html.addClass('opened');
      }
    });

    function closeMenu() {
      menuMob.removeClass('opened');
      menuMob.addClass('closed');
      html.removeClass('opened');
      burgerBtn.removeClass('opened');
      overlay.fadeOut(700);
      setTimeout(function () {
        menuMob.removeClass('closed');
      }, 750);
    }

    overlay.on('click', function () {
      closeMenu();
    });
    closeBtn.on('click', function () {
      closeMenu();
    });
    $(window).resize(function () {
      if ($(this).width() > 991 && menuMob.hasClass('opened')) {
        closeMenu();
      }
    });
    $(window).scroll(function (e) {
      if (e.currentTarget.pageYOffset > 32) {
        header.addClass('opened');
      } else {
        header.removeClass('opened');
      }
    });
    $(window).on("load", function (e) {
      if (e.currentTarget.pageYOffset > 32 && !header.hasClass('opened')) {
        header.addClass('opened');
      }
    }); // Add slideDown animation to Bootstrap dropdown when expanding.

    $('.dropdown').on('mouseenter', function () {
      $(this).addClass('show');
      $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
    }); // Add slideUp animation to Bootstrap dropdown when collapsing.

    $('.dropdown-menu, .dropdown').on('mouseleave', function (e) {
      $('.dropdown').find('.dropdown-menu').first().stop(true, true).slideUp();
    });
    var currentSlide = $('.hero-slider-item.active'),
        items = $(".hero-slider-item");

    function changeSlide(to) {
      if (to == 'next' && Number(currentSlide.attr('data-slide')) + 1 !== items.length + 1) {
        $('.hero-slider-item').each(function (_, i) {
          return $(i).removeClass('active');
        });
        currentSlide = $(".hero-slider-item[data-slide=".concat(Number(currentSlide.attr('data-slide')) + 1, "]"));
        currentSlide.addClass('active');
      } else if (to == 'prev' && Number(currentSlide.attr('data-slide')) - 1 > 0) {
        $('.hero-slider-item').each(function (_, i) {
          return $(i).removeClass('active');
        });
        currentSlide = $(".hero-slider-item[data-slide=".concat(Number(currentSlide.attr('data-slide')) - 1, "]"));
        currentSlide.addClass('active');
      }
    }

    $('.hero-slider-next').on('click', function () {
      return changeSlide('next');
    });
    $('.hero-slider-prev').on('click', function () {
      return changeSlide('prev');
    });
    var menu = $(".get-started-select-menu");
    $(menu).find('li').each(function (i, item) {
      $(item).on('click', function (e) {
        if ($(item).hasClass('opened')) {
          $(item).removeClass('opened');
        } else {
          $(menu).find("li.opened").each(function (_, i) {
            return $(i).removeClass('opened');
          });
          $(item).addClass('opened');
        }
      });
    });
    var btn = $('.to-start');
    $(window).on('scroll', function (e) {
      if ($(window).scrollTop() > 300) {
        btn.addClass('show');
      } else {
        btn.removeClass('show');
      }
    });
    btn.on('click', function (e) {
      e.preventDefault();
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
    });
    var serviceItems = $('.services-content-wrap-item-img');

    if (serviceItems.length > 0) {
      serviceItems.each(function (i, item) {
        $(item).css({
          'background-image': "url(".concat($(item).attr('data-img'), ")")
        });
      });
    }
  });
});